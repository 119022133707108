.profile-icon {
    position: relative;
    display: inline-block;
  }
  
  .profile-icon img {
    width: 100px; /* Adjust the size as needed */
    height: 100px;
    border-radius: 50%; /* Make the image round */
  }
  
  .profile-icon input[type="file"] {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity:0; /* Hide the input field */
    cursor: pointer; /* Show pointer cursor when hovering */
  }

  .custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #ccc;
    color: #fff;
    border-radius: 4px;
  }
  
  .custom-file-upload:hover {
    background-color: #aaa;
  }
