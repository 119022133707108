*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
/* .container{
    margin:5rem;
} */

.modal-wrapper{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(189, 189, 189, 0.9 );
}

.modal-container{
    position: fixed;
    top: 50%;
    left: 50% ;
    transform: translate(-50%,-50% );
    background-color: #80aaff;
    padding: 100px 100px 50px 100px ;
    border-radius: 0.5rem;
   
    max-width: 40rem;
}

.modal-input{
    /* border: 2rem; */
    border-color: black;
    background-color: white;
     width: 400px;
     height: 200px;
     padding: 1rem;
}

.modal-btn{
    width: 100px;
    padding: 0.6rem 1.4rem;
    font-size: 1.4rem;
    border: none;
    color: wheat;
    border-radius: 0.3rem;
}