.star-rating {
    font-size: 40px;
    color: #ccc;
    cursor: pointer;
  }
  
  .star-rating .star {
    display: inline-block;
    margin-right: 5px;
  }
  
  .star-rating .star.filled {
    color: gold;
  }